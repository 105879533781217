.event-list {
    #main.main-content-v {
        align-items: flex-start;

        table thead tr th {
            border: 4px solid $giallo-ocra !important;
        }

        table tbody tr td {
            border-bottom: 4px solid $giallo-ocra !important;
            border-left: 4px solid $giallo-ocra !important;
            border-right: 4px solid $giallo-ocra !important;

            a:hover {
                text-decoration: underline;
            }
        }
        &.two-boxes {
        }
    }

    #title,
    #title-0,
    #title-1,
    #title-2,
    #title-3,
    #title-4,
    #title-5,
    #title-6 {
        color: $nero;
        font-size: 22px;
        font-weight: bold;
    }

    &.two-boxes {
        #message-box {
            .evt-day {
                .inline-title {
                    .program-title {
                    }
                    .btn-form-submit {
                    }
                }

                .evt-title {
                    font-weight: bold;
                    text-align: left;
                    font-size: 20px;
                    margin-bottom: 1rem;
                }

                .evt-day-text {
                    padding: 15px 30px 15px 15px;
                    background: $itw-grigio-scuro;
                    flex-direction: column;

                    .evt-day-title {
                        text-align: left;
                        font-size: 21px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid black;
                    }

                    .evt-day-text-footer {
                        .evt-day-text-hour {
                            flex-basis: unset;
                            font-weight: normal;
                            border-bottom: unset;
                        }
                        .evt-day-text-register {
                            a.workshop-color:hover,
                            a.community-color:hover {
                                color: black !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .box-partner {
        height: auto;
        width: 300px;
        border: 5px solid black;
        display: inline-block;
        margin-top: 20px;
        margin-right: 40px;
    }
    #message-box {
        padding: 1rem;

        .box-1 {
            margin-bottom: 3rem;
        }
        .box-2 {
            margin-bottom: 3rem;
        }
        .box-3 {
            margin-bottom: 3rem;
        }

        .itw-border {
            margin-bottom: 6rem;

            .inner {
                align-items: unset;
                .extra-title {
                    font-weight: bold;
                    font-size: 24px;
                    text-align: left;
                    margin-bottom: 0.5rem;
                }
                .extra-hour {
                    font-weight: bold;
                    font-size: 14px;
                    text-align: left;
                }
                .extra-location {
                    font-weight: bold;
                    font-size: 14px;
                    text-align: left;
                    margin-bottom: 0.5rem;
                }
                .extra-description {
                    margin-bottom: 1.5rem;
                }
            }
        }

        .evt-day {
            width: 100%;
            //padding-left: 7%;
            .inline-title {
                margin-bottom: 30px;
                display: block;
                .program-title {
                    font-size: 30px;
                    font-weight: bold;
                    text-align: left;
                    //padding-right: 20%;
                }
                .btn-form-submit {
                    margin: 5px 0 0 0;
                }
            }
            .evt-day-text {
                margin-bottom: 15px;
                .evt-day-text-hour {
                    font-size: 18px;
                    text-align: left;
                    border-bottom: 1px solid black;
                    font-weight: bold;
                }
                .evt-day-text-description {
                    text-align: left;
                    font-size: 18px;
                    i {
                        font-size: 16px;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
                .evt-day-moderator {
                    text-align: left;
                    margin-bottom: 1rem;
                    font-size: 16px;
                }
            }

            .evt-day-text-last {
                margin-bottom: 3rem;
                .evt-day-text-description {
                    margin-bottom: 0;
                }
            }
        }
    }

    .evt-room-text {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 1rem;
        text-align: left;
    }

    .evt-text {
        margin-bottom: 3rem;
        font-size: 18px;
    }

    a {
        color: black;
        text-decoration: underline;
        -webkit-appearance: caret;
    }

    a:hover {
        color: $itw-magenta !important;

        &.extra {
            color: white !important;
            text-decoration: none;
        }
    }

    .table-responsive {
        @include responsive-table;
    }
    @media (min-width: 576px) {
        #title,
        #title-0,
        #title-1,
        #title-2,
        #title-3,
        #title-4,
        #title-5,
        #title-6 {
            padding: 0 2rem;
        }

        .evt-text {
            padding: 0 2rem;
        }
    }

    @media (min-width: 768px) {
        #message-box {
            .evt-day {
                padding: 2rem;
                .inline-title {
                    display: flex;
                    justify-content: space-between;
                    .program-title {
                        font-size: 35px;
                    }
                    .btn-form-submit {
                        font-size: 20px;
                    }
                }
                .evt-day-text {
                    display: flex;
                    .evt-day-text-hour {
                        border-bottom: none;
                        flex-basis: 20%;
                    }
                    .evt-day-text-description {
                    }
                }
            }
        }
    }
    @media (min-width: 992px) {
        #main.main-content-v {
            table tbody tr td {
                border: 4px solid $giallo-ocra !important;
            }
        }

        #title,
        #title-0,
        #title-1,
        #title-2,
        #title-3,
        #title-4,
        #title-5,
        #title-6 {
            font-size: 27px;
        }
    }

    @media (min-width: 1220px) {
        #main.main-content-v {
            &.two-boxes {
                //overflow-y: hidden;
            }
        }
        #message-box {
            &.extra {
                .inner {
                    flex-direction: column;
                }
            }

            &.two-boxes {
                flex-direction: row;
                justify-content: space-between;
                .box-1 {
                    margin-bottom: 0;
                    flex-basis: 50%;
                    margin: 0 1.5rem;
                }
                .box-2 {
                    flex-basis: 50%;
                    margin: 0 1.5rem;
                }
            }

            .inner {
                flex-direction: row;
            }

            .evt-day {
                .evt-day-text {
                    .evt-day-text-hour {
                        flex-basis: 30%;
                    }
                }

                .evt-day-text-last {
                    margin-bottom: 0;
                    .evt-day-text-description {
                        margin-bottom: 0;
                    }
                }

                .inline-title {
                    .program-title {
                        font-size: 30px;
                    }
                    .btn-form-submit {
                        font-size: 18px;
                    }
                }
            }
        }
        &.two-boxes {
            #message-box {
                .evt-day {
                    //height: calc(100vh - 430px);
                    //overflow-y: auto;
                    //@include scrollbars(13px, $itw-verde , $nero );
                    .inline-title {
                        .program-title {
                        }
                        .btn-form-submit {
                        }
                    }

                    .evt-day-text {
                        .evt-day-title {
                            font-size: 18px;
                            border-bottom: unset;
                        }

                        .evt-day-text-description {
                            font-size: 16px;
                        }

                        .evt-day-moderator {
                            font-size: 14px;
                        }

                        .evt-day-text-footer {
                            .evt-day-text-hour {
                                font-size: 15px;
                            }
                            .evt-day-text-register {
                                a.workshop-color:hover,
                                a.community-color:hover {
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1600px) {
        #message-box {
            .evt-day {
                .inline-title {
                    .program-title {
                        font-size: 40px;
                    }
                    .btn-form-submit {
                        font-size: 25px;
                    }
                }
            }
        }
    }
}

.event-list.event-list-community {
    .event-28 {
        margin: 0 -1rem 2rem -1rem;
        background: $itw-grigio-scuro;
    }

    .evt-text {
        margin-bottom: 1rem;
    }

    .evt-day {
        .program-title {
            font-size: 27px;
            font-weight: bold;
        }

        .evt-day-text {
            padding: 15px 30px 15px 15px;
            background: $itw-grigio-scuro;
            flex-direction: column;

            .evt-day-title {
                text-align: left;
                font-size: 21px;
                margin-bottom: 10px;
                border-bottom: 1px solid black;
            }

            .evt-day-text-description {
            }

            .evt-day-text-footer {
                .evt-day-text-hour {
                    flex-basis: unset;
                    font-weight: normal;
                    border-bottom: unset;
                }
                .evt-day-text-register {
                    a.workshop-color:hover,
                    a.community-color:hover {
                        color: black !important;
                    }
                }
            }
        }
    }

    @media (min-width: 1220px) {
        .event-28 {
            padding: 0 4rem;

            .inner {
                width: 48%;
            }
        }
        .evt-day {
            .program-title {
                font-size: 20px;
            }

            .evt-day-text {
                .evt-day-title {
                    font-size: 18px;
                    border-bottom: unset;
                }

                .evt-day-text-description {
                    font-size: 16px;
                }

                .evt-day-moderator {
                    font-size: 14px;
                }

                .evt-day-text-footer {
                    .evt-day-text-hour {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    @media (min-width: 1600px) {
        .evt-day {
            .inline-title {
                .program-title {
                    font-size: 40px;
                }
                .btn-form-submit {
                    font-size: 25px;
                }
            }
        }
    }
}
