@import './variables';

.lang-switch {
    font-weight: bold;
    font-size: 16px;
    color: $nero;
    line-height: 32px;
    margin: 0 5px;
    text-decoration: underline;

    &:hover,
    &.active {
        color: $itw-verde !important;
    }
}
