@mixin scrollbars($size: 10px, $foreground-color: #999, $background-color: #333, $border-radius: 8px) {
    // For Chrome & Safari
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $border-radius;
    }
    &::-webkit-scrollbar-track {
        background: $background-color;
        border-radius: $border-radius;
    }

    // Standard version (Firefox only for now)
    scrollbar-color: $foreground-color $background-color;
}

// Responsive HTML table mixin.
@mixin responsive-table() {
    thead {
        color: $white;
        display: none;
    }

    tbody {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        td {
            background-color: $white;
            display: block;
            padding: 0.75rem 1rem;
            width: 100%;

            //&::before {
            //    content: attr(aria-label);
            //    display: inline-block;
            //    font-weight: bold;
            //    margin: 0 1rem 0 0;
            //    min-width: 80px;
            //}

            &:first-child {
                background-color: $repidee-verde;
                color: $text-reverse;
                text-align: center;
                border-top: 4px solid $giallo-ocra !important;

                //&::before {
                //    display: none;
                //}
            }
        }
    }

    tr {
        display: flex;
        flex-flow: column wrap;
        flex: 0 0 100%;
        padding: 1.25rem 1rem;
        max-width: 100%;
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        tr {
            flex: 0 0 50%;
            max-width: 50%;
            width: 100%;
        }
    }

    @media screen and (min-width: 992px) {
        background-color: #efefef;

        thead {
            display: table-header-group;

            th {
                background-color: black;
                padding: 1rem;
                text-align: center;
                border-top: 4px solid $giallo-ocra !important;
            }
        }

        tbody {
            display: table-row-group;

            td {
                background-color: transparent;
                display: table-cell;
                padding: 1rem;
                text-align: center;
                width: auto;

                &::before {
                    display: none;
                }

                &:first-child {
                    background-color: transparent;
                    color: inherit;
                }
            }
        }

        tr {
            display: table-row;
            margin: 0;

            &:nth-child(odd) {
                background-color: white;
            }

            &:nth-child(even) {
                background-color: rgb(0, 0, 0, 0.005);
            }
        }
    }
}
