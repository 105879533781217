body.landing {
    header.main-header-special .main-nav {
        max-width: 650px;
        margin: 0 auto;
        //width: 100%;
    }

    #main {
        margin-top: 50px;

        .lang-mobile {
            display: flex;
            justify-content: flex-end;
        }
        .lang-desktop {
            display: none;
        }

        #message-box {
            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                padding: 3rem 1rem 3rem 1rem;

                .logo-box {
                    width: 75%;
                    margin-bottom: 1rem;
                }

                .divider-box {
                    width: 100%;
                }

                .text-box {
                    .text-title {
                        font-weight: 900;
                        font-size: 27px;
                        margin-top: 3vw;
                    }

                    .text-subtitle {
                        font-weight: 100;
                        font-size: 27px;
                        text-align: center;
                    }
                    .text-link {
                        text-align: center;
                        a {
                            color: black;
                            font-size: 20px;
                        }
                        a:hover {
                            color: $itw-verde;
                        }
                    }
                }
            }
        }

        #program-evt-box {
            background: $gusto-crema;

            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                align-items: center;
                padding: 1rem 1rem 1rem 1rem;

                .evt-description {
                    text-align: center;
                    font-size: 18px;
                    margin-bottom: 2rem;
                    text-wrap: balance;
                }

                .evt-program {
                    .title {
                        font-weight: 100;
                        font-size: 36px;
                        margin-top: 30px;
                        text-align: center;
                    }

                    .program {
                        display: flex;
                        flex-direction: column;
                        .evt-type {
                            flex-basis: 20%;
                            display: flex;
                            flex-direction: column;
                        }

                        .program-title {
                            font-weight: bold;
                            font-size: 25px;
                            text-align: center;
                        }

                        .program-text {
                            text-transform: none;
                            text-align: center;
                            flex-grow: 1;

                            a {
                                color: black;
                                text-decoration: underline;
                            }
                            a:hover {
                                color: $itw-magenta;
                            }
                        }
                    }
                }
            }
        }

        #map-box {
            border: 10px solid $itw-grigio;
            width: 100%;
            max-width: 1600px;
        }
    }

    #message-box {
        .title {
            font-size: 25px;
            font-weight: bold;
            line-height: 120%;

            &.text-regular {
                font-weight: normal;
            }
        }
        #event {
            flex-basis: 50%;
            .title {
                color: rgb(88, 178, 174);
            }
            font-size: 12px;
        }
        #workshop {
            flex-basis: 50%;
            .title {
                color: rgb(121, 157, 211);
            }
            font-size: 12px;
        }
    }

    @media (min-width: 768px) {
        #message-box .title {
            font-size: 35px;
            min-height: 220px;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        #main {
            #logo-evt-box {
                .content {
                    padding: 3rem 2rem 4rem 2rem;

                    .logo-box {
                        max-width: 500px;
                    }

                    .text-box {
                        .text-title {
                            font-size: 30px;
                        }

                        .text-subtitle {
                            font-size: 30px;
                        }
                    }
                }
            }

            #message-box {
                .content {
                    .logo-box {
                        width: 55%;
                        margin-bottom: 2rem;
                    }
                }
            }

            #program-evt-box {
                .content {
                    .evt-description {
                        font-size: 18px;
                    }

                    .evt-program {
                        .title {
                        }

                        .program {
                            .program-title {
                            }

                            .program-text {
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 992px) {
        header.main-header-special .main-nav {
            margin: 0;

            .nav-logo {
                display: block;
            }
            .nav-img-logo {
                height: 100px;
            }
        }
        #main {
            .lang-mobile {
                display: none;
            }
            .lang-desktop {
                display: flex;
                justify-content: flex-end;
            }

            #message-box {
                max-width: 1200px;
                margin: 0 auto;

                .content {
                    padding: 2rem 2rem 2rem 2rem;

                    .logo-box {
                        max-width: 250px;
                        margin-bottom: 0;
                    }

                    .text-box {
                        margin-left: 7rem;

                        .text-title {
                            margin-top: 0;
                        }

                        .text-subtitle {
                            text-align: right;
                        }
                    }
                }
            }

            #program-evt-box {
                .content {
                    .evt-description {
                        font-size: 21px;
                    }

                    .evt-program {
                        .title {
                            text-align: unset;
                        }

                        .program {
                            flex-direction: row;
                            .program-title {
                                font-size: 15px;
                                text-align: unset;
                            }

                            .program-text {
                                font-size: 14px;
                                padding-right: 2rem;
                                text-align: unset;
                                margin-bottom: 1rem;
                                button.landing {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        #message-box {
            #event {
                font-size: 18px !important;
            }
            #workshop {
                font-size: 18px !important;
            }

            .title {
                min-height: 150px;
            }

            #workshop,
            #event {
                p {
                    min-height: 230px;
                }
            }
        }

        #main {
            #logo-evt-box {
                .content {
                    padding: 6rem;
                    .logo-box {
                    }

                    .text-box {
                        .text-title {
                            font-size: 25px;
                        }

                        .text-subtitle {
                            font-size: 25px;
                        }
                    }
                }
            }

            #program-evt-box {
                .content {
                    .evt-description {
                        font-size: 24px;
                    }

                    .evt-program {
                        .title {
                        }

                        .program {
                            .program-title {
                                font-size: 16px;
                            }

                            .program-text {
                                font-size: 16px;
                                button.landing {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1600px) {
        #workshop,
        #event {
            p {
                min-height: 150px;
            }
        }

        #main {
            #logo-evt-box {
                .content {
                    padding: 6rem;

                    .logo-box {
                    }

                    .text-box {
                        .text-title {
                            font-size: 33px;
                        }

                        .text-subtitle {
                            font-size: 33px;
                        }
                    }
                }
            }
            #program-evt-box {
                .content {
                    .evt-description {
                        font-size: 24px;
                    }

                    .evt-program {
                        .title {
                        }

                        .program {
                            .program-title {
                                font-size: 20px;
                            }

                            .program-text {
                                font-size: 18px;
                                button.landing {
                                    font-size: 21px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
