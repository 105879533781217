header.main-header {
    position: relative;
    background-color: $main-header-bg;
    padding: 0.5rem 2rem;

    a {
        user-select: none;
    }

    .nav-ul-survey {
        display: none;
    }
}
nav.main-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.nav-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}

.logo-secondary {
    width: 96px;
}

.nav-gedi-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    order: 2;
}

.menu-burger {
    order: 3;
}

header.main-header.main-header-special {
    padding: 1rem 1.5rem;

    .nav-img-logo,
    .logo img {
        height: 50px;
        width: auto;
    }
}

header.main-header.main-header-menu {
    padding: 0.5rem 3vw 0.5rem 3vw;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;

    .nav-img-logo,
    .logo img {
        height: 30px;
        width: auto;
        margin-top: 15px;
    }

    //background: url(../img/bck-header.png) left bottom no-repeat,;
    background-size: contain;
}

header.main-header.main-header-logo {
    padding: 30px 15px 0 15px;

    nav.main-nav {
        //justify-content: center;
    }
}

#nav-ul-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    .evt-conference,
    .evt-workshop,
    .evt-community,
    .evt-partner {
        font-size: 20px;
        font-weight: bold;
    }

    .evt-conference:hover {
        color: $itw-magenta;
    }

    .evt-workshop:hover {
        color: $itw-verde;
    }

    .evt-community:hover {
        color: $itw-viola;
    }

    .evt-partner:hover {
        color: $itw-azzurro;
    }

    .dash {
        width: 30px;
        margin-bottom: 7px;
        margin-right: 3px;
        border-bottom: 8px solid;

        &.tech {
            border-bottom-color: $itw-magenta;
        }
        &.workshop {
            border-bottom-color: $itw-verde;
        }
        &.special {
            border-bottom-color: $itw-viola;
        }
        &.partner {
            border-bottom-color: $itw-azzurro;
        }
    }

    a.active {
        &.evt-conference {
            color: $itw-magenta !important;
        }
        &.evt-workshop {
            color: $itw-verde !important;
        }
        &.evt-community {
            color: $itw-viola !important;
        }
        &.evt-partner {
            color: $itw-azzurro !important;
        }
    }

    a.evt-menu {
        color: black;
    }

    &.open {
        transform: translateX(0);
        border-left: 2px dashed #fff;
        border-top: 2px dashed #fff;
        border-bottom: 2px dashed #fff;
        z-index: 100;
    }
}
#nav-toggler {
    display: none;
}
.lang-mobile {
    display: flex;
    justify-content: center;
}
.lang-tablet {
    display: none;
}
.lang-desktop {
    display: none;
}

header.is-live {
    .nav-ul-survey {
        display: list-item;
    }
}

.nav-ul {
    text-align: right;
    //	padding-left: 15px;
}

.nav-ul-item {
    margin-bottom: 15px;
}

.nav-ul-link {
    color: $text-color;
    font-size: 18px;
    text-transform: uppercase;
    display: inline-block;

    // &:before {
    //     content: attr(title);
    //     font-weight: 600;
    //     display: block;
    //     height: 1px;
    //     color: transparent;
    //     overflow: hidden;
    //     visibility: hidden;
    //     margin-bottom: -1px;
    // }

    &:hover,
    &:focus {
        color: $text-color;
        font-weight: 600;
    }

    //&.nav-ul-link-user {}

    &.nav-ul-link-active {
        font-weight: bold;
        position: relative;
        //text-decoration: underline;

        &:after {
            content: '';
            width: 45px;
            height: 3px;
            background-color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.nav-ul-link-user,
.link-user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-transform: uppercase;

    &:hover {
        text-decoration: none;
    }
}

.nav-logout {
    color: $text-color;
}

.ico-user {
    background-image: url('../img/user-icon-white.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 8px;
}

.nav-ul-item-dd.no-hover {
    #nav-logout {
        display: none !important;
    }

    .nav-ul-link {
        cursor: default;
    }

    .nav-ul-link:hover {
        font-weight: normal;
    }
}

@media (min-width: 768px) {
    header.main-header {
        .nav-logo {
            width: 245px;
        }
        .logo-secondary {
            width: 120px;
        }
    }
    header.main-header.main-header-menu {
        height: 100px;
        .nav-img-logo,
        .logo img {
            height: 47px;
            width: auto;
            margin-top: 0;
        }
    }

    header.main-header.main-header-special {
        padding: 2rem 1.5rem 0.5rem 1.5rem;

        .nav-img-logo,
        .logo img {
            height: 75px;
            width: auto;
        }
    }

    nav.main-nav {
        align-items: center;
        flex-direction: row;
        padding-top: 0.5rem;
        padding-bottom: 0.35rem;
    }
    .nav-logo {
        order: 2;
    }

    .nav-gedi-logo {
        width: 100px;
        order: 2;
    }

    .menu-burger {
        order: 3;
    }

    //#nav-ul-wrapper {
    //    flex-direction: row;
    //
    //    .evt-conference, .evt-workshop, .evt-community  {
    //        font-size: 16px;
    //        margin-right: 10px;
    //    }
    //
    //    .dash {
    //        margin-bottom: 26px;
    //    }
    //
    //}
    #nav-ul-wrapper {
        position: absolute;
        background-color: $bianco;
        padding: 2rem;
        top: 100%;
        right: 0;
        transform: translateX(100%);
        transition: all 0.3s ease-in-out;

        &.open {
            transform: translateX(0);
            border-left: 2px solid $itw-verde;
            border-top: 2px solid $itw-verde;
            border-bottom: 2px solid $itw-verde;
            z-index: 100;
            background-color: rgba(255, 255, 255, 0.9);

            .evt-conference,
            .evt-workshop,
            .evt-community,
            .evt-partner {
                font-size: 20px;
                font-weight: bold;
            }

            .evt-conference:hover {
                color: $itw-magenta;
            }

            .evt-workshop:hover {
                color: $itw-verde;
            }

            .evt-community:hover {
                color: $itw-viola;
            }

            .evt-partner:hover {
                color: $itw-azzurro;
            }

            .dash {
                width: 30px;
                margin-bottom: 7px;
                margin-right: 3px;
                border-bottom: 8px solid;

                &.tech {
                    border-bottom-color: $itw-magenta;
                }
                &.workshop {
                    border-bottom-color: $itw-verde;
                }
                &.special {
                    border-bottom-color: $itw-viola;
                }
                &.partner {
                    border-bottom-color: $itw-azzurro;
                }
            }

            a.active {
                &.evt-conference {
                    color: $itw-magenta !important;
                }
                &.evt-workshop {
                    color: $itw-verde !important;
                }
                &.evt-community {
                    color: $itw-viola !important;
                }
                &.evt-partner {
                    color: $itw-azzurro !important;
                }
            }

            a.evt-menu {
                color: black;
            }
        }
    }
    #nav-toggler {
        display: block;
        width: 40px;
        height: 30px;
        position: relative;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;
        appearance: none;
        background-image: none;
        background: transparent;
        border: 0px none;
        border-radius: 0;

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: $nero;
            border-radius: 9px;
            opacity: 1;
            right: 0;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
        }

        span:nth-child(1) {
            top: 0px;
        }

        span:nth-child(2),
        span:nth-child(3) {
            top: 12px;
            width: 100%;
        }

        span:nth-child(4) {
            top: 24px;
            width: 100%;
        }

        &.open {
            span:nth-child(1) {
                top: 18px;
                width: 0%;
                right: 50%;
            }

            span:nth-child(2) {
                transform: rotate(45deg);
                width: 100%;
            }

            span:nth-child(3) {
                transform: rotate(-45deg);
                width: 100%;
            }

            span:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
    .lang-mobile {
        display: none;
    }
    .lang-tablet {
        display: flex;
    }
    .lang-desktop {
        display: none;
    }
}

@media (min-width: 992px) {
    header.main-header {
        padding: 0rem 2rem;

        .nav-logo {
            width: 230px;
        }
        .logo-secondary {
            width: 120px;
        }
    }

    //#nav-toggler {
    //    display: none;
    //}
    //#nav-ul-wrapper {
    //
    //    .evt-conference, .evt-workshop, .evt-community {
    //        font-size: 20px;
    //        margin-right: 45px;
    //    }
    //    .dash {
    //        margin-bottom: 20px;
    //    }
    //}

    .nav-ul {
        display: flex;
        padding: 0;
    }

    .nav-ul-item-dd {
        position: relative;
        border-left: 3px solid #fff;

        &:hover #nav-logout {
            display: block;
        }
    }

    .nav-ul-item {
        margin: 0;

        &.first a {
            padding-left: 0px;
        }

        &.last a {
            padding-right: 0px;
        }
    }

    .nav-ul-link {
        margin: 0 20px;
        font-size: 20px;
    }

    .nav-ul-link-user {
        margin-right: 0;
    }

    #nav-logout {
        position: absolute;
        width: 100%;
        background-color: $light-background;
        padding: 10px 15px;
        text-align: right;
        top: 100%;
        left: 0;
        display: none;

        &:hover {
            display: block;
        }
    }

    .nav-logout {
        color: $text-reverse;
        font-weight: bold;

        &:hover {
            color: $text-highlight;
        }
    }
    //#nav-toggler {
    //    display: none;
    //}

    header.main-header.main-header-special {
        padding: 2rem 0vw 0rem 2vw;

        max-width: 60%;

        .nav-img-logo,
        .logo img {
            height: 75px;
            width: auto;
        }
    }

    header.main-header.main-header-logo {
        padding: 30px 15px 0 15px;
        //nav.main-nav {
        //    justify-content: center;
        //}
    }
}

@media (min-width: 1200px) {
    header.main-header {
    }

    .nav-ul-link {
        margin: 0 30px;
    }

    .nav-ul-item-dd {
        padding-left: 30px;
        margin-left: 30px;

        .nav-ul-link {
            margin-right: 0;
        }
    }
    #nav-ul-wrapper {
        position: unset;
        background-color: unset;
        padding: unset;
        top: unset;
        right: unset;
        transform: unset;
        transition: unset;
        display: flex;
        flex-direction: row;
        margin-top: unset;
        align-items: center;

        .evt-conference,
        .evt-workshop,
        .evt-community,
        .evt-partner {
            font-size: 20px;
            font-weight: bold;
            margin-right: 20px;
        }

        .evt-conference:hover {
            color: $itw-magenta;
        }

        .evt-workshop:hover {
            color: $itw-verde;
        }

        .evt-community:hover {
            color: $itw-viola;
        }
        .evt-partner:hover {
            color: $itw-azzurro;
        }

        .dash {
            width: 30px;
            margin-bottom: 20px;
            margin-right: 3px;
            border-bottom: 8px solid;

            &.tech {
                border-bottom-color: $itw-magenta;
            }
            &.workshop {
                border-bottom-color: $itw-verde;
            }
            &.special {
                border-bottom-color: $itw-viola;
            }
            &.partner {
                border-bottom-color: $itw-azzurro;
            }
        }

        a.active {
            &.evt-conference {
                color: $itw-magenta !important;
            }
            &.evt-workshop {
                color: $itw-verde !important;
            }
            &.evt-community {
                color: $itw-viola !important;
            }
            &.evt-partner {
                color: $itw-azzurro !important;
            }
        }

        a.evt-menu {
            color: black;
        }

        &.open {
            transform: translateX(0);
            border-left: 2px dashed #fff;
            border-top: 2px dashed #fff;
            border-bottom: 2px dashed #fff;
            z-index: 100;
        }
    }
    #nav-toggler {
        display: none;
    }
    .lang-mobile {
        display: none;
    }
    .lang-tablet {
        display: none;
    }
    .lang-desktop {
        display: flex;
    }
}
