#logo-box {
    margin-left: auto;
    margin-right: auto;
    max-width: 460px;
}

#countdown-box {
    .countdown-title {
        font-size: 20px;
        font-weight: 600;
    }
    .countdown-outro {
        font-size: 16px;
    }
}
#logo-box + #countdown-box {
    margin-top: -40px;
}

@media (min-width: 360px) {
    #countdown-box {
        .countdown-title {
            font-size: 24px;
        }
        .countdown-outro {
            font-size: 20px;
        }
    }
}

@media (min-width: 500px) {
}

@media (min-width: 768px) {
    #countdown-box {
        .countdown-title {
            font-size: 30px;
        }
        .countdown-outro {
            font-size: 22px;
        }
    }

    #logo-box {
        width: 100%;
    }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
    #countdown-box {
        .countdown-title {
            font-size: 36px;
        }

        .countdown-outro {
            font-size: 24px;
        }
    }

    #logo-box {
        max-width: 700px;
    }
}

@media (min-width: 1400px) {
}

@media (min-width: 1600px) {
}
