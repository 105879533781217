body.register-confirm {
    #message-box {
        color: $text-color;
        padding: 2rem 1rem;

        .message {
            font-size: 16px;
        }

        .btn-form-submit {
            margin: 0 0;
        }
    }

    .title {
        font-size: 22px;
    }

    #back-button:hover {
        color: black !important;
    }

    @media (min-width: 768px) {
        #message-box {
            padding: 2rem 5rem;
            width: 100%;
            max-width: 1700px;

            .message {
                font-size: 20px;
            }

            .title {
                font-size: 30px;
            }
        }
    }

    @media (min-width: 1400px) {
        #message-box {
            width: 90%;
            .message {
                font-size: 30px;
            }

            .title {
                font-size: 35px;
            }
        }
    }
}
